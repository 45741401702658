<template>
    <div class="content">

        <h2>Logowanie</h2>

        <div class="popup" v-if="error">
            <div>
                <div class="close" @click="error = null"></div>
                <h6>Komunikat:</h6>
                {{ error }}
            </div>
        </div>

        <div class="spacer"></div>

        <div class="row">
            <input type="text" v-model="username" value="" placeholder="Adres e-mail" />
        </div>

        <div class="row">
            <input type="text" style="max-width: 40%;" v-model="password" value="" placeholder="Numer PZWL" />
        </div>

        <button class="button" @click="login">Zaloguj się</button>

    </div>
</template>

<script>

    import { model } from "../model.js";

    export default {
        name: 'Home',
        data() {
            return {
                'error': null,
                'username': null,
                'password': null
            }
        },
        methods: {

            login: async function()
            {
                var response = await model.login(this.username, this.password);

                if (response.data.status == 'OK')
                {
                    var token = response.data.results.token;

                    if (token)
                    {
                        localStorage.setItem('token', response.data.results.token);
                        this.$router.push({ name: 'Dashboard' });
                        setTimeout(function() {
                            location.reload();
                        }, 100);

                    }
                }
                else
                {
                    this.error = response.data.message;
                }
            }

        }
    }
</script>
